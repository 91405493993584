import { Stack } from '@mui/material';
import { AssignmentStatus, TAssignment } from 'src/api/assignments/models';
import { theme } from 'src/theme';
import { Status } from './Task.styled';
import { ReactComponent as ResolvedIcon } from 'src/icons/18x18/resolved.svg';
import { ReactComponent as RejectedIcon } from 'src/icons/18x18/rejected.svg';
import { ReactComponent as PendingIcon } from 'src/icons/18x18/ClockClockwise.svg';

type TaskStatusProps = {
  status?: AssignmentStatus;
  isMobile?: boolean;
  isTablet?: boolean;
  fromCurrentModule: boolean;
  actor?: string;
  assignmentType?: TAssignment;
};

const TaskStatus: React.FC<TaskStatusProps> = ({
  status,
  isMobile,
  isTablet,
  fromCurrentModule,
  actor,
  assignmentType,
}) => {
  const isMakeAnAppointment = assignmentType === TAssignment.MakeAnAppointment;
  const getTextAndIcon = () => {
    switch (status) {
      case AssignmentStatus.Completed: {
        return { text: 'Resolved', icon: <ResolvedIcon /> };
      }
      case AssignmentStatus.Incomplete: {
        return { text: 'Pending', icon: <PendingIcon /> };
      }
      case AssignmentStatus.Cancelled: {
        return { text: 'Rejected', icon: <RejectedIcon /> };
      }
    }
  };
  const statusInfo = getTextAndIcon();

  if (isMobile || !statusInfo || (status === AssignmentStatus.Incomplete && !isMakeAnAppointment)) return null;

  if (isMakeAnAppointment) {
    return (
      <Stack direction="row" gap="6px" marginTop="16px" alignItems="center">
        {statusInfo.icon}
        <Status module={fromCurrentModule ? 1 : 0} color={theme.palette.grey[700]}>
          {statusInfo.text}
        </Status>
      </Stack>
    );
  }

  return (
    <Stack direction="row" gap="6px" marginTop="16px" alignItems="center">
      {statusInfo.icon}
      <Status module={fromCurrentModule ? 1 : 0} marginRight="6px" color={theme.palette.grey[700]}>
        {statusInfo.text}
        {!isTablet ? ` by` : ''}
      </Status>
      {!isTablet && actor && (
        <Status module={fromCurrentModule ? 1 : 0} actor={actor} color={theme.palette.grey[700]}>
          {actor}
        </Status>
      )}
    </Stack>
  );
};

export default TaskStatus;
